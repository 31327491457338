import React from "react";

import Layout from "../Layout/Layout";
import SEO from "../Layout/Seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="not-found-wrapper">
      <div className="not-found-wrapper__content">
        <p className="not-found-wrapper__header">BAD NEWS</p>
        <p className="not-found-wrapper__paragraph">
          Sorry, I can’t find the page you are looking for...
        </p>
        <p className="not-found-wrapper__header">GOOD NEWS </p>
        <p className="not-found-wrapper__paragraph">
          Still we can stay in touch and discuss what you need
        </p>
        <p>
          <a href="/contact">Let’s talk</a> or get back to the <a href="/"> main page</a>.
        </p>
        <div className="floating-image">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            clipRule="evenodd"
            viewBox="0 0 200 200"
          >
            <clipPath id="mask">
              <path
                d="M27.8-31.2c12.9 8.9 31.3 13.2 39 24.3 7.8 11.1 5 28.9-3.5 43.3C54.8 50.9 40.7 62 23.8 70.2 6.9 78.5-12.8 83.9-26.4 77-40 70.1-47.5 50.9-57.2 33.4-67 15.8-79-.1-79-16.3c0-16.2 12.1-32.7 27.2-41.2 15.2-8.5 33.5-9.1 46.4-2.7 12.8 6.5 20.3 20.1 33.2 29z"
                transform="translate(100 100)"
              />
            </clipPath>
            <image
              width="100%"
              height="100%"
              clipPath="url(#mask)"
              preserveAspectRatio="xMidYMid slice"
              href="https://wp.pandagroup.co/wp-content/uploads/2021/07/404Panda.png"
            />
          </svg>
          <div className="not-found-wrapper__404">
            <span>404</span>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
